import { useEffect, useState } from 'react';
import queryString from 'query-string';
import slugify from 'slugify';
import useFetch from './hooks/fetch';
import Card from './components/Card';

function App() {
  const [filteredData, setFilteredData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [filters, setFilters] = useState({});
  const { data, loading } = useFetch();

  const url = new URL(window.location);

  useEffect(() => {
    const { query } = queryString.parseUrl(window.location.href);
    setFilters(query);
  }, []);

  useEffect(() => {
    if (!data.length) return;
    const topicsSet = Array.from(new Set(data.map(({ Tema }) => Tema)));
    const candidatesSet = Array.from(
      new Set(data.map(({ Candidato }) => Candidato)),
    ).map((candidateName) => candidateName);

    setTopics(topicsSet);
    setCandidates(candidatesSet);
  }, [data]);

  useEffect(() => {
    const topic = filters.topic || '';
    const filteredByTopic = data
      .filter((record) => record.Tema === topic);
    const filteredByCandidateA = filteredByTopic
      .filter((record) => record.Candidato === filters.ca);
    const filteredByCandidateB = filteredByTopic
      .filter((record) => record.Candidato === filters.cb);
    const results = [...filteredByCandidateA, ...filteredByCandidateB];
    setFilteredData(results);
  }, [data, filters]);

  const handleShare = () => {
    Object.entries(filters).forEach(([param, value]) => {
      if (!value) return;
      url.searchParams.set(param, value);
      window.history.pushState({}, '', url);
    });
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-12 font-montserrat">
      {loading ? <p>Cargando</p>
        : (
          <>
            <div className="text-center lg:text-left">
              <h1 className="font-bold uppercase text-xl inline-block px-2 py-1 bg-orange-yellow-crayola md:text-2xl">
                Comparador de discurso
              </h1>
            </div>
            <div className="mt-6 flex flex-col gap-y-6 lg:flex-row lg:gap-y-0 lg:gap-x-6">
              <div className="flex flex-col items-center lg:items-start">
                <label htmlFor="topics" className="text-lg text-space-cadet font-bold">Temas</label>
                <select
                  id="topics"
                  className="mt-2 border border-black rounded-md text-xs md:text-base"
                  value={filters.topic || ''}
                  onChange={(e) => setFilters((prevState) => (
                    { ...prevState, topic: e.target.value }
                  ))}
                >
                  <option value="">Seleccione un tema</option>
                  {topics.map((topic) => (
                    <option value={topic} key={topic}>{topic}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col items-center lg:items-start">
                <label className="text-lg text-space-cadet font-bold">
                  Seleccione a dos candidatos
                  {' '}
                  <span className="hidden md:inline">para comparar</span>
                </label>
                <div className="mt-2 flex flex-col gap-y-2 sm:flex-row sm:gap-y-0 sm:items-center sm:gap-x-4">
                  <select
                    value={filters.ca || ''}
                    className="border border-black rounded-md text-xs md:text-base"
                    onChange={(e) => setFilters((prevState) => ({
                      ...prevState,
                      ca: e.target.value,
                    }))}
                  >
                    <option value="">Selecciona un candidato</option>
                    {candidates
                      .filter((candidate) => candidate !== filters.cb)
                      .map((candidate) => (
                        <option value={candidate} key={candidate}>{candidate}</option>
                      ))}
                  </select>
                  <p className="text-space-cadet font-bold text-center">vs.</p>
                  <select
                    value={filters.cb || ''}
                    className="border border-black rounded-md text-xs md:text-base"
                    onChange={(e) => setFilters((prevState) => ({
                      ...prevState,
                      cb: e.target.value,
                    }))}
                  >
                    <option value="">Selecciona un candidato</option>
                    {candidates
                      .filter((candidate) => candidate !== filters.ca)
                      .map((candidate) => (
                        <option value={candidate} key={candidate}>{candidate}</option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <div className="text-center relative">
                <span className="absolute top-0 left-1/2 bottom-0 -translate-x-1/2 bg-orange-yellow-crayola -rotate-2 w-1/5" />
                <span className="absolute top-0 left-1/2 bottom-0 -translate-x-1/2 bg-yellow-orange rotate-2 w-1/5" />
                <h2 className="font-bold text-xl relative inline-block md:text-2xl">
                  {filters.topic ? <span>{filters.topic}</span> : <span>&nbsp;</span>}
                </h2>
              </div>
            </div>
            <div className="mt-10 flex flex-col gap-8 items-center md:flex-row md:justify-center md:items-stretch">
              {filteredData.map((record) => (
                <Card key={slugify(record.Candidato, { lower: true })} record={record} />
              ))}
            </div>
            <button type="button" onClick={handleShare} className="mt-4 inline-block bg-yellow-orange px-4 py-2 font-bold">Compartir</button>
          </>
        )}
    </div>
  );
}

export default App;
