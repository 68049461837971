import { useEffect, useReducer } from 'react';

const initialState = {
  data: [],
  loading: true,
  error: null,
};

const ACTIONS = {
  API_REQUEST: 'api-request',
  FETCH_DATA: 'fetch-data',
  ERROR: 'error',
};

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.API_REQUEST:
      return { ...state, loading: true };
    case ACTIONS.FETCH_DATA:
      return { ...state, loading: false, data: payload };
    case ACTIONS.ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
}

function useFetch() {
  const url = 'https://script.google.com/macros/s/AKfycbzhKQhqcJ_LTrq67BIPJMV2nLrmcq0iGlsq6Ns07KIJqgGmlsT0yxsQNzvVSjtJzgVU/exec';
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: ACTIONS.API_REQUEST });
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: ACTIONS.FETCH_DATA, payload: json.data });
      })
      .catch((error) => {
        dispatch({ type: ACTIONS.ERROR, payload: error.message });
      });
  }, []);

  return state;
}

export default useFetch;
