import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

/* eslint-disable react/prop-types */
function Card({ record }) {
  return (
    <div
      className="px-8 py-6 rounded-3xl bg-center bg-cover bg-no-repeat"
      style={{
        backgroundImage: "url('/bg-card.png')",
        width: '100%',
        maxWidth: '347px',
        minHeight: '334px',
      }}
    >
      <h3 className="border-b border-space-cadet text-space-cadet pb-2">{record.Candidato}</h3>
      <div className="mt-4 text-sm md:text-base">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {record.Propuesta}
        </ReactMarkdown>
      </div>
    </div>
  );
}

export default Card;
